// 企业认证
<template>
  <div class="addAuthentication">
    <backtitle />
    <!-- 提交列表数据 -->
    <div class="mianBox">
      <!-- 头部步骤 -->
      <div class="step" v-if='changeState!=="YDRZ"'>
        <div class="stepBox">
          <div class="stateBox">
            <div class="imgBox">
              <img
                :src="enterpriseState?require('@/assets/sgin/001active.png'):require('@/assets/sgin/001@2x.png')"
                :width="enterpriseState?'54px':'40px'"
                :height="enterpriseState?'54px':'40px'"
              />
            </div>
            <div :style="{'color': enterpriseState?'#38373a':'#757380'}">填写认证信息</div>
          </div>
          <div class="line"></div>
          <div class="stateBox">
            <div class="imgBox">
              <img
                :src="enterpriseState?require('@/assets/sgin/002@2x.png'):require('@/assets/sgin/002@active.png')"
                :width="enterpriseState?'40px':'54px'"
                :height="enterpriseState?'40px':'54px'"
              />
            </div>
            <div :style="{'color':enterpriseState? '#757380':'#38373a'}">小额打款校验</div>
          </div>
        </div>
      </div>
      <!-- 企业信息 -->
      <div class="formBox"  v-if="enterpriseState">
        <title-center name="企业信息"></title-center>
        <base-form
          :componentList="enterpriseForm"
          :showBtns="false"
          :formAttrs="{
            model: enterpriseQuery,
          }"
          class="formStyle"
          ref="enterpriseData"
        >
        </base-form>
      </div>
      <!-- 账户信息 -->
      <div class="formBox"  v-if="enterpriseState">
        <title-center name="账户信息"></title-center>
        <base-form
          :componentList="accountFrom"
          :showBtns="false"
          :formAttrs="{
            model: accountQuery,
          }"
          class="formStyle"
          ref="accountData"
        >
        </base-form>
      </div>
      <!-- 签章人信息 -->
      <div class="formBox" v-if="enterpriseState">
        <title-center name="签章人信息">
          <template slot="footer">
            <div style="font-size: 14px">
              无签章人信息？可点击<span class="addBtn" @click="addSigner"
                >新增</span
              >
            </div>
          </template>
        </title-center>
        <!-- 表单 -->
        <base-form
          :componentList="signerFrom"
          :showBtns="false"
          :formAttrs="{
            model: signerQuery,
          }"
          class="formStyle"
          ref="signerData"
        >
        </base-form>
      </div>
      <!-- 小额打款 -->
      <div class="formBox" v-if="!enterpriseState">
        <title-center name="小额打款校验"></title-center>
        <div class="inputPayment">
          <div><i class="el-icon-success"></i></div>
          <div class="fontDK">打款已受理，请注意查收！</div>
          <div  class="amount">
            <amount-input v-model="paymentAmount" placeholder="请输入打款金额" type = 'WY'></amount-input>
            <base-button class="rePayment" label="重新打款" v-if="accountProving.accountProvingAvailablNo===0" @click="rePayment"></base-button>
          </div>
          <div class="accountProving">
            <div>总次数：<span>{{accountProving.accountProvingMax}}</span></div>
            <div>剩余次数：<span>{{accountProving.accountProvingAvailablNo}}</span> </div>
            <div>失败次数：<span>{{accountProving.accountProvingFailureNo}}</span> </div>
          </div>
        </div>
      </div>
      <!--用户协议 -->
      <div class="agereement" v-if="enterpriseState&&changeState!=='BGGYS'">
        <el-checkbox v-model="checked"> <span style="color:#EE1C1C">*</span> 我已同意</el-checkbox>
        <span @click="showAgreement('0')">《CFCA数字证书服务协议》</span>
        <span @click="showAgreement('1')">《安心签平台服务协议》</span>
        <span @click="showAgreement('2')">《安心签平台隐私条款》</span>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="footer">
      <!-- 企业提交 -->
      <base-button label="提交" v-if="enterpriseState" @click="submitEnterprise"/>
      <!-- 企业校验 -->
      <base-button label="校验" v-if="!enterpriseState" @click="confirmAmount"/>
      <!-- 特殊类型提交 -->
      <base-button label="关闭" @click="$router.back()" v-if="enterpriseState" type='default' />
      <base-button label="返回" v-if="!enterpriseState" @click="backFirstStep" type='default'/>
    </div>
    <!-- 用户协议弹出框 -->
    <agreement-dialog
      :visible.sync="agreementVisible"
      :title="title"
      :agreementType="agreementType"
    ></agreement-dialog>
    <!-- 新增签章人弹窗 -->
    <add-signer :visible.sync="addSginerVisible" :enterpriseId='enterpriseId'></add-signer>
  </div>
</template>
<script>
import backtitle from '@/pages/business/components/backtitle.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import TitleCenter from '@/components/packages/title-center/title-center.vue'
import AgreementDialog from './components/agreement-dialog.vue'
import BaseForm from '@/components/common/base-form//base-form'
import AddSigner from './components/add-signer.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
import { certifyApi, supplierApi } from '@/api/companyApi'
import { contactApi } from '@/api/contactApi'

import {
  signerConfig,
  accountConfig,
  enterpriseConfig
} from './utils/add-authentication-config'
export default {
  components: {
    backtitle,
    BaseButton,
    AgreementDialog,
    TitleCenter,
    BaseForm,
    AddSigner,
    AmountInput
  },
  data () {
    return {
      agreementVisible: false, // 用户协议弹框
      agreementType: '', // 协议类型
      addSginerVisible: false, // 新增签章人弹窗
      scanVisible: false, // 二维码弹窗
      enterpriseState: true, // 企业认证状态（进入打款状态）  ture 编辑状态  false 打款状态
      paymentAmount: '', // 打款金额
      checked: false, // 选协议
      accountValid: false, // 账户校验
      signerValid: false, // 签章人校验
      title: '', // 协议标题
      accountQuery: {}, // 银行信息
      enterpriseQuery: {}, // 企业信息
      signerQuery: { }, // 签章人信息
      enterpriseId: '', // 企业id
      accountKeyId: '', // 账户id
      signerkeyId: '', // 签章人ID
      bankList: [], // 银行下拉
      personnelList: [], // 签证人下拉
      accountProving: {}, // 打款次数
      changeState: '', // 编辑变更状态
      sealAdminType: '', // 认证类型
      sealAdminId: '', // 签章人id
      authStatus: '' // 认证状态
    }
  },
  created () {
    // 获取供应商id
    this.orgAnizationId = this.$store.state.user.userInfo.organizationId
    // 变更编辑状态
    this.changeState = this.$route.query.changeState
    // 编辑企业的签章人id
    this.sealAdminId = this.$route.query.sealAdminId
  },
  mounted () {
    this.$set(this.signerQuery, 'isDefault', '1')
    this.getGysInfo()
    this.$nextTick(() => {
      this.getBankList()
    })
  },
  computed: {
    signerFrom () {
      return signerConfig(this)
    },
    accountFrom () {
      return accountConfig(this)
    },
    enterpriseForm () {
      return enterpriseConfig(this)
    }
  },
  watch: {
    enterpriseId (val) {
      if (val) {
        this.getGysPersonnelList() // 签章人下拉
        this.getEnterpriseStutas() // 认证状态
        this.checkRemoteOrgCertifyStatus() // 远端认证状态
      }
    },
    changeState (val) {
      if (val === 'BGGYS') {
        this.enterpriseState = true
      }
    },
    enterpriseState (val) {
      if (!val && this.enterpriseId) {
        this.getVerficationNum()// 打款次数
      }
    },
    sealAdminId (val) {
      if (val) {
        this.getEnterpriseStutas() // 变更情况下,携带签章人id查询
      }
    }
  },
  methods: {
    // 显示用户协议弹窗 三种不同协议
    showAgreement (data) {
      const MpaList = ['《CFCA数字证书服务协议》', '《安心签平台服务协议》', '《安心签平台隐私条款》']
      MpaList.forEach((item, index) => {
        if (data === index.toString()) {
          this.title = item
          this.agreementType = index.toString()
          this.agreementVisible = true
        }
      })
    },
    // 获取企业信息 enterpriseId
    getGysInfo () {
      if (this.orgAnizationId) {
        supplierApi.getEnterpriseInfo({ orgAnizationId: this.orgAnizationId }).then(res => {
          this.enterpriseQuery = { ...res.data, creditName: '社会统一代码' }
          this.enterpriseId = res.data.keyId
        })
      }
    },
    // 获取银行下拉
    getBankList () {
      certifyApi.getBankList().then(res => {
        this.bankList = res.data
      })
    },
    // 签证人下拉
    getGysPersonnelList () {
      contactApi.getGysPersonnelList({ enterpriseId: this.enterpriseId }).then(res => {
        if (res.data) {
          this.personnelList = res.data
        }
      })
    },
    // 远端认证状态  获取银行信息
    checkRemoteOrgCertifyStatus () {
      if (this.changeState === 'YDRZ') {
        certifyApi.checkRemoteOrgCertifyStatus({ enterpriseId: this.enterpriseId }).then(res => {
          // 认证状态(0:未认证 / 1:认证中 / 2:认证成功 / 3:认证失败)
          if (res.code === '0') {
            this.accountQuery = { account: res.data.accountName, bankId: res.data.accountBank, accountNo: res.data.accountNumber }
          }
        })
      }
    },
    // 查询 本地 认证状态
    getEnterpriseStutas () {
      const prams = {
        enterpriseId: this.enterpriseId, sealAdminId: this.sealAdminId
      }
      certifyApi.getEnterpriseStutas(prams).then(res => {
        // 认证状态为 认证中或者是编辑供应商
        if (res.data.companyInfoVO) {
          this.enterpriseState = res.data.companyInfoVO.authStatus !== '1' || this.changeState === 'YDRZ'
          this.authStatus = res.data.companyInfoVO.authStatus
        }
        if (res.data.companyBankAccountInfoVO) {
          this.accountQuery = res.data.companyBankAccountInfoVO
          this.$set(this.accountQuery, 'bankId', res.data.companyBankAccountInfoVO.bankId)
          this.accountKeyId = res.data.companyBankAccountInfoVO.keyId
        }
        if (res.data.sealAdminDetailVO) {
          this.$set(this.signerQuery, 'personId', res.data.sealAdminDetailVO.personnelInfoVO.keyId)
          this.$set(this.signerQuery, 'idCard', res.data.sealAdminDetailVO.personnelInfoVO.idCard)
          this.$set(this.signerQuery, 'phone', res.data.sealAdminDetailVO.personnelInfoVO.mobilePhoneNo)
          this.$set(this.signerQuery, 'email', res.data.sealAdminDetailVO.personnelInfoVO.email)
          this.$set(this.signerQuery, 'isDefault', res.data.sealAdminDetailVO.sealAdminInfoVO.isDefault.toString())
        }
        // 认证类型
        if (res.data.sealAdminDetailVO) {
          this.sealAdminType = res.data.sealAdminDetailVO.sealAdminInfoVO.sealAdminType
          this.signerQuery.keyId = res.data.sealAdminDetailVO.sealAdminInfoVO.keyId
        }
      })
    },
    // 选择人员后回显
    changeSigner (data) {
      const [obj] = this.personnelList.filter(item => item.keyId === data)
      if (obj) {
        this.$set(this.signerQuery, 'idCard', obj.idCard)
        this.$set(this.signerQuery, 'phone', obj.mobilePhoneNo)
        this.$set(this.signerQuery, 'email', obj.email)
      } else {
        this.$set(this.signerQuery, 'idCard', '')
        this.$set(this.signerQuery, 'phone', '')
        this.$set(this.signerQuery, 'email', '')
      }
    },
    // 返回编辑内容
    backGetInfo () {
      const prams = {
        enterpriseId: this.enterpriseId, sealAdminId: this.sealAdminId
      }
      certifyApi.getEnterpriseStutas(prams).then(res => {
        // 认证状态为 认证中或者是编辑供应商
        if (res.data.companyInfoVO) {
          // this.enterpriseState = res.data.companyInfoVO.authStatus !== '1' || this.changeState === 'YDRZ'
          this.authStatus = res.data.companyInfoVO.authStatus
        }
        if (res.data.companyBankAccountInfoVO) {
          this.accountQuery = res.data.companyBankAccountInfoVO
          this.$set(this.accountQuery, 'bankId', res.data.companyBankAccountInfoVO.bankId)
          this.accountKeyId = res.data.companyBankAccountInfoVO.keyId
        }
        if (res.data.sealAdminDetailVO) {
          this.$set(this.signerQuery, 'personId', res.data.sealAdminDetailVO.personnelInfoVO.keyId)
          this.$set(this.signerQuery, 'idCard', res.data.sealAdminDetailVO.personnelInfoVO.idCard)
          this.$set(this.signerQuery, 'phone', res.data.sealAdminDetailVO.personnelInfoVO.mobilePhoneNo)
          this.$set(this.signerQuery, 'email', res.data.sealAdminDetailVO.personnelInfoVO.email)
          this.$set(this.signerQuery, 'isDefault', res.data.sealAdminDetailVO.sealAdminInfoVO.isDefault.toString())
        }
        // 认证类型
        if (res.data.sealAdminDetailVO) {
          this.sealAdminType = res.data.sealAdminDetailVO.sealAdminInfoVO.sealAdminType
          this.signerQuery.keyId = res.data.sealAdminDetailVO.sealAdminInfoVO.keyId
        }
      })
    },
    // 返回上一步
    backFirstStep () {
      this.enterpriseState = true
      this.backGetInfo()
    },
    // 提交企业
    submitEnterprise () {
      // 变更 默认选中协议
      if (this.changeState === 'BGGYS') {
        this.checked = true
      }
      this.$refs.accountData.validate(valid => { this.accountValid = valid })
      this.$refs.signerData.validate(valid => { this.signerValid = valid })
      if (!this.accountValid || !this.signerValid) {
        this.error('请完整填写认证信息')
        return
      }
      if (!this.checked) {
        this.error('请阅读并勾选用户协议')
        return
      }

      if (this.checked & this.signerValid && this.accountValid) {
        const [obj] = this.bankList.filter(item => item.bankId === this.accountQuery.bankId)
        this.accountQuery.bankName = obj ? obj.bankName : ''
        const data = {
          enterpriseId: this.enterpriseId,
          bankAccount: { enterpriseId: this.enterpriseId, keyId: this.accountKeyId, ...this.accountQuery },
          sealAdminInfo: {
            enterpriseId: this.enterpriseId,
            ...this.signerQuery,
            sealAdminType: '1',
            enableStatus: 1
          }
        }

        if (this.changeState === 'YDRZ') { // 特殊类型提交  该类型是'远端认证'
          certifyApi.saveCertifyBankAndRegisterSealAdmin(data).then(res => {
            if (res.data) {
              this.success('签章人已添加成功')
              this.$router.back()
            } else {
              this.error('提交失败，请稍后重试')
            }
          })
        } else { // 普通类型提交
          certifyApi.submitEnterprise(data).then(res => {
            if (res.data) {
              this.success('提交成功进行打款校验')
              this.enterpriseState = false
            } else {
              this.error('提交失败，请稍后重试')
            }
          })
        }
      }
    },
    // 打款校验
    confirmAmount () {
      if (this.paymentAmount) {
        const params = {
          enterpriseId: this.enterpriseId,
          amount: parseFloat(this.paymentAmount * 1000 / 10)
        }
        certifyApi.confirmAmount(params).then(res => {
          if (res.code === '0' && res.data) {
            this.$router.back()
            return
          }
          this.getVerficationNum()
        }).catch((error) => {
          this.getVerficationNum()
          console.log(error)
        })
      } else {
        this.warning('请输入打款金额')
      }
    },
    // 打款次数
    getVerficationNum () {
      certifyApi.getVerficationNum({ enterpriseId: this.enterpriseId }).then(res => {
        if (res.code === '0') {
          this.$set(this.accountProving, 'accountProvingMax', res.data.accountProvingMax)
          this.$set(this.accountProving, 'accountProvingAvailablNo', res.data.accountProvingAvailablNo)
          this.$set(this.accountProving, 'accountProvingFailureNo', res.data.accountProvingFailureNo)
        }
      })
    },
    // 重新请求打款
    rePayment () {
      certifyApi.rePayment({ enterpriseId: this.enterpriseId }).then(res => {
        if (res.data) {
          this.success('请求成功')
          this.paymentAmount = ''
          this.getVerficationNum()
        }
      })
    },
    // 返回企业认证
    backSubmitEnterprise () {
      this.enterpriseState = true
    },
    // 新增签章人
    addSigner () {
      this.addSginerVisible = true
    },
    // clearEmailValidate () {
    //   this.$refs.signerData.clearValidate('email')
    // },
    clearPhoneValidate (data) {
      this.signerQuery.phone = data.replace(/[^\d]/g, '')
    },
    // 账户信息
    changeAccountNo (data) {
      this.accountQuery.accountNo = data.replace(/[^\d]/g, '')
    }

  }
}
</script>
<style lang="scss" src="./index.scss" scoped/>
